import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media } from 'theme/styled-theme';
import { sendEvent } from 'utils/gtag';
import mediaItn from 'assets/media-itn-logo.jpg';
import mediaYahoo from 'assets/media-yahoo-logo.jpg';
import mediaTia from 'assets/media-tia-logo.jpg';
import mediaTravHq from 'assets/media-travhq-logo.jpg';
import mediaTae from 'assets/media-tae-logo.jpg';

import PageSectionHeaders from '../PageSectionHeaders/Loadable';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Medias = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${media.forPhoneOnly`
    justify-content: space-between;
  `}
`;

const Media = styled.div`
  width: 150px;
  margin: 0 0.625rem 2rem;
  display: inline-block;

  ${media.forPhoneOnly`
    margin: 0 0 2rem;
  `}
`;

const MediaSection = () => {
  const { t } = useTranslation('new-version');

  return (
    <Root className="container">
      <PageSectionHeaders
        title={t('HOME.MEDIA.HEADING')}
        subtitle={t('HOME.MEDIA.SUBHEADING')}
        center
      />
      <Medias>
        <Media>
          <a
            href="https://www.intltravelnews.com/2018/ownrides-drivers-taiwan"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendEvent({ action: 'Click Media Image', category: 'engagement', label: 'International Travel News' });
            }}
          >
            <img src={mediaItn} alt="International Travel News" />
          </a>
        </Media>
        <Media>
          <a
            href="https://sg.news.yahoo.com/startups-taiwan-bringing-benchmark-next-level-010037708.html"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendEvent({ action: 'Click Media Image', category: 'engagement', label: 'Yahoo News' });
            }}
          >
            <img src={mediaYahoo} alt="Yahoo News" />
          </a>
        </Media>
        <Media>
          <a
            href="https://www.travhq.com/industry/startup-feature/taiwanese-travel-startup-ownrides-is-disrupting-the-driver-market-for-tourists/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendEvent({ action: 'Click Media Image', category: 'engagement', label: 'TravHQ' });
            }}
          >
            <img src={mediaTravHq} alt="TravHQ" />
          </a>
        </Media>
        <Media>
          <a
            href="https://www.techinasia.com/5-international-startups-meet-tiasg2018"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendEvent({ action: 'Click Media Image', category: 'engagement', label: 'Tech In Asia' });
            }}
          >
            <img src={mediaTia} alt="Tech In Asia" />
          </a>
        </Media>
        <Media>
          <a
            href="https://www.asianentrepreneur.org/benson-lu-ownrides/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendEvent({ action: 'Click Media Image', category: 'engagement', label: 'The Asian Entrepreneurs' });
            }}
          >
            <img src={mediaTae} alt="The Asian Entrepreneurs" />
          </a>

        </Media>
      </Medias>
    </Root>
  );
};

MediaSection.propTypes = {
};

MediaSection.defaultProps = {
};

export default MediaSection;
